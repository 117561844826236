<template>

    <div class="bigBox">
        <div class="xmrgTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toBack">
            <p class="xmrgTopBoxTitle">项目认购</p>
        </div>
        <div style="height:.88rem"></div>


        <div class="xmrgBox">
            <div class="xmrgBox1">
                <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/安全_1686556216923.png" class="xmrgBox1Img1">
                <p class="xmrgBox1P">资金安全有保障</p>
                <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组16_1686556485128.png" class="xmrgBox1Img2">
            </div>

            <div class="xmrgBox2">
                <div class="xmrgBox2Top">
                    <p class="xmrgBox2TopP1">买入金额</p>
                    <p class="xmrgBox2TopP2">交易规则</p>
                </div>
                <div class="xmrgBox2Box1">
                    <p class="xmrgBox2Box1P1">¥</p>
                    <input type="number" class="numIpt" v-model="numValue" placeholder="最低买入118,988.00元">
                </div>
                <div class="xmrgBox2Box2">
                    <div class="xmrgBox2Box2Item" @click="changeNumValue(200000)">20万元</div>
                    <div class="xmrgBox2Box2Item" @click="changeNumValue(500000)">50万元</div>
                    <div class="xmrgBox2Box2Item" @click="changeNumValue(1000000)">100万元</div>
                </div>
                <div style="height:.02rem;background: rgba(112, 112, 112, .2);marginBottom:.26rem;"></div>
                <div class="xmrgBox2Box3">
                    注：此金额只做确认项目份额。
                    <br/>买入项目需向平台缴纳成交金额的3%的保证金，待双方信息审核确认通过后（5-7个工作日），打款至项目出品公司，签署《产权交易合同》并扣除手续费。
                </div>
            </div>

            <div class="xmrgBox3">
                <p class="xmrgBox3Title">项目概要</p>
                <div class="xmrgBox3Box1">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/安全_1686556216923.png" class="xmrgBox3Box1Img">
                    为了保障您的投资权益，在做出投资决定前，请您阅读完整的招募说明书等文件。
                </div>
                <p class="xmrgBox3Title2">一、产品概况</p>
                <div class="xmrgBox3Box2">
                    基金简称:诺安成长混合
                    <br/>基金代号:320007
                    <br/>基金管理人:诺安基金管理有限公司
                    <br/>基金托管人:中国工商银行股份有限公司
                    <br/>基金合同生效日期:2009年03月10日
                    <br/>冶碍伴埠癌艾金类型: 混合型
                    <br/>交易币种:人民币
                    <br/>运作方式:普通开放式
                    <br/>开放频率:每个开放日
                    <br/>基金经理:蔡嵩松
                    <br/>开始担任本基金经理的日期:2019年02月20日
                    <br/>证券从业日期:2015年09月01日
                    <br/>基金经理:刘慧影
                    <br/>开始担任本基金经理的日期:2023年02月18日
                    <br/>证券从业日期:2015年07月01日
                </div>
                <div v-if="moreFlag" class="moreBtn" @click="toMore">查看全部</div>
                <div v-else class="moreBtn" @click="toMore2">收起</div>


            </div>



        </div>





        <div class="xmrgBottom">
            <div class="xmrgBottomBox">
                <div v-if="!isAccept" class="xmrgBottomFixedBox">
                    <span>请勾选</span>
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径460_1686562508658.png">
                </div>
                <img v-if="isAccept" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组738_1686562388551.png" class="xmrgBottomBoxImg1" @click="isAccept = false">
                <img v-else src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/椭圆135_1686561619719.png" class="xmrgBottomBoxImg1" @click="isAccept = true">

                <p class="xmrgBottomBoxP">
                    点击确定即代表您已知悉该项目的
                    <span style="color:#A30001;">投资者权益须知</span>
                    等相关内容
                </p>
            </div>
            <div class="xmrgBottomBoxBtn" :style="{'background': isAccept?'#A30001':'#DEAFAF'}" @click="onOk">确&nbsp;&nbsp;定</div>

        </div>



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister,addMovieDelistedOrder} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            moreFlag:true,
            isAccept:false,
            numValue:null,
            load : null,
            moviefrom: [],
            wjsToken: null,
            wjsUserInfo: null,
            xmId:1,
        }
    },
    methods:{

        changeNumValue(val){
            this.numValue = val;
        },
        toMore(){
            $('.xmrgBox3Box2').css('-webkit-line-clamp','inherit')
            this.moreFlag = false;
        },
        toMore2(){
            $('.xmrgBox3Box2').css('-webkit-line-clamp','10')
            this.moreFlag = true;
        },

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },1500)
        },

        toBack(){
            this.$router.back(-1)
        },
        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },

        onOk(){
            if(!this.numValue){
                Message.error({
                    message: "请输入认购金额",
                    center: true,
                    duration:2000,
                    showClose: false,
                })
                return;
            }

            addMovieDelistedOrder({
                "orderAmount": this.numValue,
                "projectId": this.xmId
            }).then(res => {
                console.log(res)
                if(res.code == 200){
                    Message.success({
                        message: res.message,
                        center: true,
                        duration:2000,
                        showClose: false,
                    })



                    // setTimeout(() => {
                    //     this.toLink('wdxm');
                    // }, 1500);
                }
                if(res.code == 401){
                    Message.error({
                        message: '登录信息失效，请重新登录!',
                        center: true,
                        duration:2000,
                        showClose: false,
                    })
                    // setTimeout(() => {
                    //     this.toLink('dl');
                    // }, 2000);
                }
            })


        }

            

    },
    mounted(){
        
        if(!this.$route.params.xmId){
            this.toLink('index')
        }else{
            console.log(this.$route.params.xmId)
            this.xmId = this.$route.params.xmId;


        }
        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        this.showLoading()
        this.load && this.load.close();
    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .xmrgTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .xmrgTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }


    .xmrgBox{
        background: #F6F6F6;
        position: relative;
        padding-top: .12rem;
        padding-bottom: 3rem;
        .xmrgBox1{
            background-color: #ECF2FE;
            height: .54rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .xmrgBox1Img1{
                width: .2rem;
                height: .22rem;
                object-fit: cover;
                margin-right: .08rem;
            }
            .xmrgBox1P{
                font-size: .24rem;
                color: #4870E9;
                line-height: .54rem;
                margin-right: .1rem;
            }
            .xmrgBox1Img2{
                width: .08rem;
                height: .16rem;
                object-fit: cover;
            }
        }
        .xmrgBox2{
            background-color: #fff;
            padding: .32rem .4rem;
            box-sizing: border-box;
            margin-bottom: .12rem;
            .xmrgBox2Top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: .38rem;
                .xmrgBox2TopP1{
                    font-size: .32rem;
                    font-weight: bold;
                    color: #343434;
                }
                .xmrgBox2TopP2{
                    font-size: .32rem;
                    color: #4870E9;
                }
            }
            .xmrgBox2Box1{
                display: flex;
                margin-bottom: .4rem;
                .xmrgBox2Box1P1{
                    font-size: .68rem;
                    font-weight: bold;
                    margin-right: .22rem;
                }
                .numIpt{
                    border: 0;
                    outline: none;
                    font-size: .3rem;
                    color: #343434;
                }
            }
            .xmrgBox2Box2{
                display: flex;
                align-items: center;
                margin-bottom: .3rem;
                .xmrgBox2Box2Item{
                    background: #A30001;
                    border-radius: .04rem;
                    font-size: .24rem;
                    color: #FFFFFF;
                    padding: .08rem .36rem;
                    margin-right: .2rem;
                }
            }
            .xmrgBox2Box3{
                font-size: .24rem;
                color: #A1A1A1;
                text-align: justify;
                line-height: .36rem;
            }
        }
        .xmrgBox3{
            padding: .26rem .4rem;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            .xmrgBox3Title{
                font-size: .32rem;
                font-weight: bold;
                color: #343434;
                margin-bottom: .12rem;
            }
            .xmrgBox3Box1{
                background: #ECF2FE;
                border-radius: .08rem;
                padding: .18rem .14rem;
                box-sizing: border-box;
                font-size: .24rem;
                font-weight: 400;
                color: #5F5F5F;
                line-height: .36rem;
                margin-bottom: .2rem;
                .xmrgBox3Box1Img{
                    width: .24rem;
                    height: .28rem;
                    object-fit: cover;
                    display: inline;
                }
            }
            .xmrgBox3Title2{
                font-size: .24rem;
                font-weight: bold;
                color: #343434;
                margin-bottom: .16rem;
            }
            .xmrgBox3Box2{
                font-size: .24rem;
                font-weight: 400;
                color: #A1A1A1;
                line-height: .38rem;
                position: relative;
                display: -webkit-box;    
                -webkit-box-orient: vertical;    
                -webkit-line-clamp: 10;    
                margin-bottom: .16rem;
                overflow: hidden;
            }
            .moreBtn{
                font-size: .28rem;
                color: #4870E9;
                text-align: center;
                margin: 0 auto;
                display: block;
            }
        }





    }

    .xmrgBottom{
        height: 2.8rem;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        padding: .46rem .5rem;
        box-sizing: border-box;
        .xmrgBottomBox{
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-bottom: .2rem;
            .xmrgBottomFixedBox{
                position: absolute;
                top: -.8rem;
                left: -.1rem;
                width: 1.1rem;
                height: .7rem;
                font-size: .26rem;
                color: #FFFFFF;
                text-align: center;

                span{
                    position: relative;
                    z-index: 2;
                    text-align: center;
                    line-height: .5rem;
                }
                img{
                    width: 1.1rem;
                    height: .7rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    z-index: 1;
                }
            }
            .xmrgBottomBoxImg1{
                width: .34rem;
                height: .34rem;
                object-fit: cover;
                margin-right: .2rem;
                margin-top: .04rem;
            }
            .xmrgBottomBoxP{
                font-size: .24rem;
                color: #A1A1A1;
                line-height: .36rem;
            }
            
        }
        .xmrgBottomBoxBtn{
            background: #DEAFAF;
            border-radius: .08rem;
            height: .9rem;
            font-size: .36rem;
            color: #FFFFFF;
            line-height: .9rem;
            text-align: center;
        }
    }







}



</style>